import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
export default {
  data: () => ({
    locked_out: false,
    lockedUntil: null,
    valid: false,
    userId: '',
    userIdRules: [v => !!v || 'UserID eingeben'],
    password: '',
    showPassword: false,
    loading: false,
    failMessage: '',
    loginFail: false
  }),
  methods: {
    login() {
      if (this.$refs.loginform.validate()) {
        if (this.locked_out) return;
        this.loading = true;
        this.$store.dispatch('login', {
          userId: this.userId,
          password: this.password
        }).catch(error => {
          this.loading = false;
          this.loginFail = true;
          this.valid = false;
          this.$store.state.apps.shaking = true;
          if (!error.response) {
            this.failMessage = 'Der Login ist aufgrund eines unbekannten Fehlers fehlgeschlagen!';
            return this.errorDialog = true;
          } else if (error.response.status == 401) {
            this.failMessage = 'Falsche UserID oder Passwort!';
          } else if (error.response.status == 403) {
            const blockedUntilJson = error.response.data['blockedUntil'];
            const blockedUntilMoment = blockedUntilJson ? DateTime.fromISO(blockedUntilJson) : DateTime.now().plus({
              minutes: 10
            });
            this.lockedUntil = blockedUntilMoment.toFormat('HH:mm');
            this.failMessage = `Aufgrund zu vieler fehlgeschlagenen Anmeldeversuchen ist die Anmeldung bis ${this.lockedUntil} gesperrt!`;
            this.locked_out = true;
            setTimeout(() => {
              this.locked_out = false;
            }, blockedUntilMoment.diffNow().toMillis());
          }
          setTimeout(() => {
            this.$store.state.apps.shaking = false;
          }, 750);
        });
      }
    }
  },
  computed: {
    ...mapState({
      shaking: state => state.apps.shaking
    }),
    ...mapGetters(['company', 'loggedIn'])
  },
  mounted() {
    if (this.$route.query.logout) {
      this.$toast.info('Erfolgreich abgemeldet.');
    }
  }
};