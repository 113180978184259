import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "white",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c(VLayout, {
    attrs: {
      "align-center": "",
      "justify-center": ""
    }
  }, [_c(VFlex, [_c(VForm, {
    ref: "loginform",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c(VCard, {
    class: {
      'mx-auto': true,
      shake: _vm.shaking
    },
    attrs: {
      "elevation": "1",
      "max-width": "1000"
    }
  }, [_c(VRow, [_c(VCol, {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('img', {
    staticClass: "px-4",
    staticStyle: {
      "max-width": "500px"
    },
    attrs: {
      "src": require("@/assets/LBA-WEB.svg"),
      "alt": ""
    }
  })]), _c(VCol, {
    staticClass: "grey lighten-4",
    staticStyle: {
      "height": "400px"
    },
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c(VContainer, {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c(VLayout, {
    attrs: {
      "align-center": "",
      "justify-center": ""
    }
  }, [_c(VFlex, [_c(VCardTitle, {
    staticClass: "justify-center",
    staticStyle: {
      "font-size": "2.3em"
    }
  }, [_c(VContainer, {
    staticClass: "d-flex justify-center align-center"
  }, [_vm._v(" LBA-WEB "), _c('VersionChip', {
    staticClass: "ml-3",
    attrs: {
      "color": "primary",
      "small": ""
    }
  })], 1)], 1), _c(VCardText, {
    staticClass: "pt-5"
  }, [_c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VTextField, {
    staticClass: "input-field",
    staticStyle: {
      "max-width": "400px"
    },
    attrs: {
      "rules": _vm.userIdRules,
      "label": "UserID",
      "required": "",
      "prepend-inner-icon": "mdi-account",
      "minlength": "3",
      "solo": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.userId,
      callback: function ($$v) {
        _vm.userId = $$v;
      },
      expression: "userId"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VTextField, {
    staticStyle: {
      "max-width": "400px"
    },
    attrs: {
      "label": "Passwort",
      "required": "",
      "solo": "",
      "prepend-inner-icon": "mdi-lock",
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "type": _vm.showPassword ? 'text' : 'password'
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_vm.locked_out == true ? _c(VBtn, {
    staticClass: "px-10",
    attrs: {
      "form": "loginform",
      "color": "primary",
      "elevation": "0",
      "loading": _vm.loading,
      "disabled": ""
    },
    on: {
      "click": _vm.login,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
      }
    }
  }, [_vm._v("Login")]) : _c(VBtn, {
    staticClass: "px-10",
    attrs: {
      "form": "loginform",
      "type": "submit",
      "color": "primary",
      "elevation": "0",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Login")])], 1), _vm.loginFail ? _c('div', {
    staticClass: "d-flex justify-center mt-5"
  }, [_c('p', {
    staticClass: "red--text text-center"
  }, [_vm._v(_vm._s(_vm.failMessage))])]) : _vm._e()])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };